import React, { Component } from 'react'
import Img from "gatsby-image"
import _ from 'lodash'
import Checkbox from './checkbox'

class PartyStep2 extends Component {
  componentWillMount = () => {
    this.selectedCheckboxes = new Map()
    if (Object.keys(this.props.getStore().partyExtras).length > 0) {
      for (let t of this.props.getStore().partyExtras) {
        this.selectedCheckboxes.set(t.name, t.price)
      }
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  toggleExtrasCheckbox = (label, price) => {
    if (this.selectedCheckboxes.has(label)) {
      this.selectedCheckboxes.delete(label)
    } else {
      this.selectedCheckboxes.set(label, price)
    }

    const partyExtras = []
    for (const [name, value] of this.selectedCheckboxes.entries()) {
      partyExtras.push({'name': name, 'price': value})
    }
    this.props.updateStore({
      partyExtras
    })
  }

  render() {
    const allPartyExtras = this.props.allPartyExtras

    return (
        <div className="party-steps">
          <h3>Extras</h3>

          <h4>Do you need some party extras to help cater for your party?</h4>
          <p className="helper">Hire these items to add some extra WOW to your party.</p>
          <ul>
            {
              allPartyExtras.map((extra, e) => (
                  <li key={e} className="list-item-option">
                    <Checkbox
                        label={`${extra.node.acf.extra_name} - $${extra.node.acf.cost}`}
                        price={parseInt(extra.node.acf.cost, 10)}
                        defaultChecked={_.find(this.props.getStore().partyExtras, ['name', extra.node.acf.extra_name]) !== undefined}
                        handleCheckboxChange={this.toggleExtrasCheckbox}
                        key={extra.node.acf.extra_name}
                        id={extra.node.acf.extra_name}
                    />
                    <p className="helper">{extra.node.acf.short_description}</p>
                    <div className="table">
                      <div className="tr">
                        <div className="td img">
                          <Img className="party-extra" fluid={extra.node.acf.image.thumb.childImageSharp.fluid} title={extra.node.acf.extra_name} alt={extra.node.acf.extra_name} />
                        </div>
                        <div className="td">
                          <div className="description">
                            <ul>
                              {
                                extra.node.acf.description.map((description, d) => (
                                    <li key={d}>{description.item}</li>
                                ))
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
              ))
            }
          </ul>
        </div>
    )
  }
}

export default PartyStep2
