import React, { Component } from "react"
import { graphql } from "gatsby"
import StepZilla from 'react-stepzilla'
import moment from 'moment'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SleepoverStep1 from "../components/sleepover-step-1"
import PartyStep2 from "../components/party-step-2"
import PartyStep3 from "../components/party-step-3"
import PartyStep4 from "../components/party-step-4"
import PartyStep5 from "../components/party-step-5"

class SleepoverPackagesPage extends Component {
  constructor(props) {
    super(props)

    const allThemes = props.data.allWordpressWpSleepoverThemes.edges
    const smarty3Price = props.data.allWordpressSpPrice.edges[0].node.sleepover_smarty_three
    const smarty5Price = props.data.allWordpressSpPrice.edges[0].node.sleepover_smarty_five

    if (this.props.location.hash === '#smarty3') {
      this.qty = 3
      this.state = {
        diy: false,
        partyPackage: {'name': `${allThemes[0].node.acf.theme_name} sleepover package`, 'price': parseInt(smarty3Price, 10)},
        partyTheme: {'index': 0, 'name': allThemes[0].node.acf.theme_name, 'price': 0},
        numberOfTents: 3,
        extraTents: 0,
        tentExtras: this.getTentExtras(allThemes),
        sleepoverExtras: [],
        partyExtras: this.getPartyExtras(allThemes),
        partyDate: moment().add(7, 'days'),
        setupOption: {'option': 'Delivery + Setup', 'price': 0},
        delivery: {'zone': 'Zone 1', 'price': 0},
        depositPaid: false,
        runningTotal: 199,
      }
    }
    //else if (this.props.location.hash === '#smarty5') {
    else {
      this.qty = 5
      this.state = {
        diy: false,
        partyPackage: {'name': `${allThemes[0].node.acf.theme_name} sleepover package`, 'price': parseInt(smarty5Price, 10)},
        partyTheme: {'index': 0, 'name': allThemes[0].node.acf.theme_name, 'price': 0},
        numberOfTents: 5,
        extraTents: 0,
        tentExtras: this.getTentExtras(allThemes),
        sleepoverExtras: [],
        partyExtras: this.getPartyExtras(allThemes),
        partyDate: moment().add(7, 'days'),
        setupOption: {'option': 'Delivery + Setup', 'price': 0},
        delivery: {'zone': 'Zone 1', 'price': 0},
        depositPaid: false,
        runningTotal: 299,
      }
    }
  }

  getTentExtras = allThemes => {
    const tentExtras = []
    for (let j = 0; j < allThemes[0].node.acf.styling_items.length; j++) {
      if(allThemes[0].node.acf.styling_items[j].quantity === 'multiple') {
        tentExtras.push({'name': allThemes[0].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return tentExtras
  }

  getPartyExtras = allThemes => {
    const partyExtras = [];
    for (let j = 0; j < allThemes[0].node.acf.styling_items.length; j++) {
      if(allThemes[0].node.acf.styling_items[j].quantity === 'single') {
        partyExtras.push({'name': allThemes[0].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return partyExtras
  }

  getStore = () => {
    return this.state
  }

  updateStore = update => {
    console.log('updateStore()', update)

    const numberOfTents = (update.numberOfTents !== undefined ? update.numberOfTents : this.state.numberOfTents)
    const extraTents = (update.extraTents !== undefined ? update.extraTents : this.state.extraTents)

    const runningTotalBeforeUpdate = this.state.runningTotal

    let sleepoverExtrasPrice = 0
    if (update.sleepoverExtras) {
      for(let t of update.sleepoverExtras) {
        sleepoverExtrasPrice += t.price * numberOfTents
      }
    }
    else {
      if (Object.keys(this.state.sleepoverExtras).length > 0) {
        for(let t of this.state.sleepoverExtras) {
          sleepoverExtrasPrice += t.price * numberOfTents
        }
      }
    }

    let partyPackagePrice = (update.partyPackage ? update.partyPackage.price : this.state.partyPackage.price)

    let partyExtrasPrice = 0
    if (update.partyExtras) {
      for(let t of update.partyExtras) {
        partyExtrasPrice += t.price
      }
    }
    else {
      if (Object.keys(this.state.partyExtras).length > 0) {
        for(let t of this.state.partyExtras) {
          partyExtrasPrice += t.price
        }
      }
    }

    let setupOptionPrice = 0
    if (update.setupOption) {
      setupOptionPrice = update.setupOption.price
    }
    else {
      setupOptionPrice = this.state.setupOption.price
    }

    let deliveryPrice = 0
    if (update.delivery) {
      deliveryPrice = update.delivery.price
    }
    else {
      deliveryPrice = this.state.delivery.price
    }

    const runningTotal = partyPackagePrice + (40 * extraTents) + sleepoverExtrasPrice + partyExtrasPrice + setupOptionPrice + deliveryPrice

    this.setState({
      ...this.state,
      ...update,
      runningTotal,
    });

    if (runningTotalBeforeUpdate !== runningTotal) {
      this.setState({ isUpdated: true })
    }

    setTimeout(() => {
      this.setState({ isUpdated: false })
    }, 1000)
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    const pageClass = currentPage.title.replace(/\s+/g, '-').toLowerCase()
    const allThemes = this.props.data.allWordpressWpSleepoverThemes.edges
    const allSleepoverExtras = this.props.data.allWordpressWpSleepoverExtras.edges
    const allPartyExtras = this.props.data.allWordpressWpPartyExtras.edges
    const smarty3Price = this.props.data.allWordpressSpPrice.edges[0].node.sleepover_smarty_three
    const smarty5Price = this.props.data.allWordpressSpPrice.edges[0].node.sleepover_smarty_five
    const allEvents = this.props.data.allWordpressTribeEvents.edges
    const termsPage = this.props.data.termsPage
    const calendarIcon = this.props.data.calendarIcon
    const deliveryArea = this.props.data.deliveryArea
    const poweredByStripe = this.props.data.poweredByStripe
    const steps =
        [
          {name: 'Themes', component: <SleepoverStep1 getStore={() => this.getStore()} updateStore={u => this.updateStore(u)} qty={this.qty} smarty3Price={smarty3Price} smarty5Price={smarty5Price} allThemes={allThemes} allSleepoverExtras={allSleepoverExtras} />},
          {name: 'Extras', component: <PartyStep2 getStore={() => this.getStore()} updateStore={u => this.updateStore(u)} allPartyExtras={allPartyExtras} />},
          {name: 'Details', component: <PartyStep3 getStore={() => this.getStore()} updateStore={u => this.updateStore(u)} allEvents={allEvents} termsPage={termsPage} calendarIcon={calendarIcon} deliveryArea={deliveryArea} />},
          {name: 'Payment', component: <PartyStep4 getStore={() => this.getStore()} updateStore={u => this.updateStore(u)} poweredByStripe={poweredByStripe} />},
          {name: 'Thanks', component: <PartyStep5 />}
        ];

    return (
        <Layout>
          <SEO title={currentPage.title} />
          <div className="container">
            <div className="row">
              <div className="span12">
                <section className="title-section">
                  <h1 className="title-header" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                </section>
                <div className={`page-${pageClass}`}>
                  <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
                  <div className="step-progress">
                    <StepZilla
                        steps={steps}
                        stepsNavigation={false}
                        prevBtnOnLastStep={false}
                        nextTextOnFinalActionStep={'Let\'s Party!'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {
              this.state.depositPaid === false && (
                  <div className={`${this.state.isUpdated ? 'throb' : ''} cart-running-total`}>
                    Your "{this.state.partyTheme.name}" party with {this.state.numberOfTents} tents:<br />
                    <strong>${this.state.runningTotal}</strong>
                  </div>
              )
            }
          </div>
        </Layout>
    )
  }
}

export default SleepoverPackagesPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "sleepover-packages" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
    }
    allWordpressWpSleepoverThemes(sort: { fields: menu_order }) {
      edges {
        node {
          acf {
            theme_name
            images {
              image {
                thumb: localFile {
                  childImageSharp {
                    fluid(maxWidth: 368) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                fullsize: localFile {
                  childImageSharp {
                    fluid(maxWidth: 2592) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            styling_image {
              thumb: localFile {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              fullsize: localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            styling_items {
              item
              quantity
            }
          }
        }
      }
    }
    allWordpressWpSleepoverExtras(sort: { fields: menu_order }) {
      edges {
        node {
          acf {
            extra_name
            image {
              thumb: localFile {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              fullsize: localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            cost
            short_description
            description {
              item
            }
          }
        }
      }
    }
    allWordpressWpPartyExtras(sort: { fields: menu_order }) {
      edges {
        node {
          acf {
            extra_name
            image {
              thumb: localFile {
                childImageSharp {
                  fluid(maxWidth: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              fullsize: localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            cost
            short_description
            description {
              item
            }
          }
        }
      }
    }
    allWordpressSpPrice {
      edges {
        node {
          sleepover_smarty_three
          sleepover_smarty_five
        }
      }
    }
    allWordpressTribeEvents {
      edges {
        node {
          events {
            title
            start_date
            end_date
            categories {
              name
            }
          }
        }
      }
    }
    termsPage: wordpressPage(slug: { eq: "terms" }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
    }
    calendarIcon: file(relativePath: { eq: "calendarIcon.png" }) {
      childImageSharp {
        fixed(width: 22, height: 24) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    deliveryArea: file(relativePath: { eq: "deliveryArea.png" }) {
      childImageSharp {
        fixed(width: 611, height: 440) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    poweredByStripe: file(relativePath: { eq: "poweredByStripe.png" }) {
      childImageSharp {
        fixed(width: 149, height: 41) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
