import React, { Component } from 'react'
import Img from "gatsby-image"
import NumericInput from 'react-numeric-input'
import _ from 'lodash'
import Checkbox from './checkbox'
import ThemeModal from './themeModal'
import StylingModal from './stylingModal'

class SleepoverStep1 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      themeModalVisible: false,
      themeModalContents: props.allThemes[0],
      stylingModalVisible: false,
      stylingModalContents: props.allThemes[0],
    }
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  componentWillMount = () => {
    this.selectedExtrasCheckboxes = new Map()
    if (Object.keys(this.props.getStore().sleepoverExtras).length > 0) {
      for (let t of this.props.getStore().sleepoverExtras) {
        this.selectedExtrasCheckboxes.set(t.name, t.price)
      }
    }
  }

  handleThemeChange = e => {
    const i = parseInt(e.target.value, 10)
    this.props.updateStore({
      partyPackage: {'name': `${this.props.allThemes[i].node.acf.theme_name} sleepover package`, 'price': this.props.qty === 3 ? parseInt(this.props.smarty3Price, 10) : parseInt(this.props.smarty5Price, 10)},
      partyTheme: {'index': i, 'name': this.props.allThemes[i].node.acf.theme_name, 'price': 0},
      tentExtras: this.getTentExtras(i),
      partyExtras: this.getPartyExtras(i),
    })
  }

  getTentExtras = i => {
    const tentExtras = []
    for (let j = 0; j < this.props.allThemes[i].node.acf.styling_items.length; j++) {
      if(this.props.allThemes[i].node.acf.styling_items[j].quantity === 'multiple') {
        tentExtras.push({'name': this.props.allThemes[i].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return tentExtras
  }

  getPartyExtras = i => {
    const partyExtras = [];
    for (let j = 0; j < this.props.allThemes[i].node.acf.styling_items.length; j++) {
      if(this.props.allThemes[i].node.acf.styling_items[j].quantity === 'single') {
        partyExtras.push({'name': this.props.allThemes[i].node.acf.styling_items[j].item, 'price': 0})
      }
    }
    return partyExtras
  }

  setThemeModalVisible = visible => {
    this.setState({
      themeModalVisible: visible
    })
  }

  openThemeModal = i => {
    this.setState({
      themeModalContents: this.props.allThemes[i]
    })
    this.setThemeModalVisible(true)
  }

  setStylingModalVisible = visible => {
    this.setState({
      stylingModalVisible: visible
    })
  }

  openStylingModal = i => {
    this.setState({
      stylingModalContents: this.props.allThemes[i]
    })
    this.setStylingModalVisible(true)
  }

  handleExtraTents = valueAsNumber => {
    this.props.updateStore({
      extraTents: valueAsNumber,
      numberOfTents: this.props.qty + valueAsNumber
    })
  }

  toggleExtrasCheckbox = (label, price) => {
    if (this.selectedExtrasCheckboxes.has(label)) {
      this.selectedExtrasCheckboxes.delete(label)
    } else {
      this.selectedExtrasCheckboxes.set(label, price)
    }

    const sleepoverExtras = []
    for (const [name, value] of this.selectedExtrasCheckboxes.entries()) {
      sleepoverExtras.push({'name': name, 'price': value})
    }
    this.props.updateStore({
      sleepoverExtras
    })
  }

  render() {
    const allThemes = this.props.allThemes
    const allSleepoverExtras = this.props.allSleepoverExtras

    return (
        <div className="party-steps package-steps">
          <h3>Choose your theme</h3>

          <h4>Select your theme</h4>
          <p className="helper">The tent for your 1x guest of honour will be decked out in the chosen theme and your {this.props.qty - 1}x other guest tents will have covers in coordinated colours. Every theme includes all styling items to add that WOW factor. The styling packages for each theme are put together based on the age and gender of the party guests.</p>

          <section className="lazy-load-box effect-slideup">
            <ul className="posts-grid row-fluid unstyled home-page-post-grid ul-item-0">
              {
                allThemes.map((item, i) => (
                    <li key={i} className={`span4 list-item-${i+1}`}>
                      <h4>{item.node.acf.theme_name}</h4>
                      <figure className="featured-thumbnail thumbnail">
                        <a onClick={() => this.openThemeModal(i)}>
                          <Img fluid={item.node.acf.images[0].image.thumb.childImageSharp.fluid} title={item.node.acf.theme_name} alt={item.node.acf.theme_name} />
                          <span className="zoom-icon"></span>
                        </a>
                      </figure>

                      <ThemeModal item={this.state.themeModalContents} themeModalVisible={this.state.themeModalVisible} setThemeModalVisible={this.setThemeModalVisible} />

                      <ul>
                        <li className="list-item-option">
                          <label className="package-label">Styling includes</label>
                          <div className="table">
                            <div className="tr">
                              <div className="td img styling-thumbnail">
                                <a onClick={() => this.openStylingModal(i)}>
                                  <Img fluid={item.node.acf.styling_image.thumb.childImageSharp.fluid} className="tent-extra" title={`${item.node.acf.theme_name} styling`} alt={`${item.node.acf.theme_name} styling`} />
                                  <span className="zoom-icon"></span>
                                </a>
                              </div>
                              <div className="td">
                                <div className="description">
                                  <ul>
                                    {
                                      item.node.acf.styling_items.map((style, s) => (
                                          <li key={s}>{`${style.quantity === 'multiple' ? this.props.qty : '1'}x`} {style.item}</li>
                                      ))
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="clear"></div>
                      <div className="radio">
                        <input id={`theme${i+1}`} type="radio" name="theme" value={i} checked={this.props.getStore().partyPackage.name === `${item.node.acf.theme_name} sleepover package`} onChange={this.handleThemeChange} />
                        <label htmlFor={`theme${i+1}`}>{item.node.acf.theme_name} theme</label>
                      </div>

                      <StylingModal item={this.state.stylingModalContents} stylingModalVisible={this.state.stylingModalVisible} setStylingModalVisible={this.setStylingModalVisible} />
                    </li>
                ))
              }
            </ul>

            {
              this.props.qty === 3 &&
                <div>
                  <h4>You have more than 3 sleeping over?</h4>
                  <div className="input-number">
                    <NumericInput
                        className="number-of-tents"
                        min={0}
                        max={1}
                        value={this.props.getStore().extraTents}
                        onChange={this.handleExtraTents}
                    />
                    <p className="helper">This is how many extra guests you would like to add to your Smarty 3 package. Minimum of 0 extras, maximum of 1 extra.</p>
                    <p className="helper">If you have 5 or more sleeping over, consider selecting a Smarty 5 package.</p>
                  </div>
                </div>
            }

            {
              this.props.qty === 5 &&
                <div>
                  <h4>You have more than 5 sleeping over?</h4>
                  <div className="input-number">
                    <NumericInput
                        className="number-of-tents"
                        min={0}
                        max={5}
                        value={this.props.getStore().extraTents}
                        onChange={this.handleExtraTents}
                    />
                    <p className="helper">This is how many extra guests you would like to add to your Smarty 5 package. Minimum of 0 extras, maximum of 5 extras.</p>
                  </div>
                </div>
            }

            <h4>Add some sleepover extras for each person?</h4>
            <p className="helper">Add some fun elements for each guest.</p>
            <ul>
              {
                allSleepoverExtras.map((extra, e) => (
                    <li key={e} className="list-item-option extra">
                      <Checkbox
                          label={`${extra.node.acf.extra_name} - $${extra.node.acf.cost} per person`}
                          price={parseInt(extra.node.acf.cost, 10)}
                          defaultChecked={_.find(this.props.getStore().sleepoverExtras, ['name', extra.node.acf.extra_name]) !== undefined}
                          handleCheckboxChange={this.toggleExtrasCheckbox}
                          key={extra.node.acf.extra_name}
                          id={extra.node.acf.extra_name}
                      />
                      <p className="helper">{extra.node.acf.short_description}</p>
                      <div className="table">
                        <div className="tr">
                          <div className="td img">
                            <Img className="sleepover-extra" fluid={extra.node.acf.image.thumb.childImageSharp.fluid} title={extra.node.acf.extra_name} alt={extra.node.acf.extra_name} />
                          </div>
                          <div className="td">
                            <div className="description">
                              <ul>
                                {
                                  extra.node.acf.description.map((description, d) => (
                                      <li key={d}>{description.item}</li>
                                  ))
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                ))
              }
            </ul>
          </section>
        </div>
    )
  }
}

export default SleepoverStep1
